@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,600i");

/* -----------------------------------------------------------
  Reset
-------------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
}

iframe {
  border: none;
  display: block;
  z-index: 98;
}

/* -----------------------------------------------------------
  Variables
-------------------------------------------------------------- */
:root {
  /* Colour definitions */
  --indicates-many: #8cc63f;
  --indicates-some: #f8f816;
  --indicates-few: #eea423;
  --indicates-none: #e45513;
  --indicates-focus: #0170bc;
}

/* -----------------------------------------------------------
  Box sizing
-------------------------------------------------------------- */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* -----------------------------------------------------------
  Base typography
-------------------------------------------------------------- */
body {
  background-color: black;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 100%; /* stylelint-disable-line */
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-size-adjust: none;
}

h2 {
  font-size: 2.5rem;
}

/* -----------------------------------------------------------
  Display layout and components
-------------------------------------------------------------- */
.display-wrapper {
  padding: 2rem;
}

.current-session {
  border-bottom: 4px solid dimgray;
  margin-bottom: 2rem;
}

.current-session time {
  font-size: 9rem;
  line-height: 1;
}

.current-session p {
  font-size: 5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.queued-sessions {
  display: inline-block;
  width: 60%;
}

.queued-sessions ul {
  list-style: none;
  margin-bottom: 2rem;
}

.queued-sessions time {
  display: inline-block;
  font-size: 4rem;
  width: 60%;
}

.queued-sessions p {
  display: inline-block;
  font-size: 3rem;
  font-weight: 600;
  text-align: right;
  width: 40%;
}

.qr {
  display: none;
}

.qr-code {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  width: 40%;
}

.qr-code div {
  background-color: #161616;
  border-radius: 0.75rem;
  display: inline-block;
  padding: 2.5rem;
}

.qr-code p {
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.qr-code svg path {
  stroke: white;
}

/* -----------------------------------------------------------
  Box office layout and components
-------------------------------------------------------------- */
.boxoffice-wrapper {
  background-color: #111;
  height: 100vh;
}

.boxoffice-wrapper header {
  padding: 2rem;
  position: relative;
}

.boxoffice-wrapper .awm-logo {
  position: absolute;
  right: 1rem;
  top: 2rem;
}

.boxoffice-wrapper .clock {
  display: block;
  font-size: 2rem;
  text-align: center;
}

.boxoffice-wrapper .clock span {
  display: inline-block;
  font-weight: 600;
  margin-left: 2rem;
  text-align: left;
  width: 200px;
}

.boxoffice-wrapper .scan-counter {
  background-color: black;
  border-radius: 0.75rem;
  line-height: 1;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
}

.boxoffice-wrapper .scan-counter span {
  display: block;
  font-size: 3rem;
  margin-top: 0.5rem;
}

.boxoffice-wrapper main {
  padding: 1rem;
}

.boxoffice-wrapper table {
  background-color: #111;
  border-spacing: 0 2px;
  width: 100%;
}

.boxoffice-wrapper tr {
  position: relative;
}

.boxoffice-wrapper th,
.boxoffice-wrapper td {
  padding: 0.5rem;
  text-align: center;
}

.boxoffice-wrapper tbody {
  color: #999;
}

.boxoffice-wrapper .booking-link a {
  color: white;
}

.boxoffice-wrapper tr.is-current {
  background-color: black;
  color: white;
  font-weight: 600;
  outline: 3px solid #666;
}

.boxoffice-wrapper tr.is-complete td {
  background-color: #333;
  color: #999;
}

/* -------------------------------------------------------------
  Responsive sizing
-------------------------------------------------------------- */
@media all and (min-width: 1200px) {
  .boxoffice-wrapper main {
    align-items: stretch;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .boxoffice-wrapper .scan-counter {
    left: 1rem;
    margin-top: 0;
    position: absolute;
    top: 1rem;
  }

  .boxoffice-wrapper table a::after {
    content: "";
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .boxoffice-wrapper tbody tr:not(.is-complete):focus-within,
  .boxoffice-wrapper tbody tr:not(.is-complete):hover {
    background-color: var(--indicates-focus);
    color: white;
  }
}

/* -----------------------------------------------------------
  Misc styles
-------------------------------------------------------------- */
.remaining-many {
  color: var(--indicates-many);
}

.remaining-some {
  color: var(--indicates-some);
}

.remaining-few {
  color: var(--indicates-few);
}

.remaining-none {
  color: var(--indicates-none);
}

.hidden {
  display: none;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
